import React, { FC, useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { AgentQaContainer } from './AgentQa/AgentQaContainer';
import { Chat, ConversationData } from '../types';

export const AgentQaCard: FC<{ ticketData: ConversationData; analysis: Chat[] }> = ({ ticketData, analysis }) => {
  const [openQAAgent, setOpenQAAgent] = useState<boolean>(false);
  return (
    <>
      <div className="flex w-full justify-between rounded-lg bg-indigo-50 px-4 py-3">
        <div>
          <div className="flex gap-2">
            <span className="font-sherika text-2xl font-semibold text-indigo-600">
              {Number.parseFloat(ticketData?.qaPreformanceScore?.split('%')[0])?.toFixed() + '%'}
            </span>
            <div className="mt-0.5">
              <p className="rounded-xl bg-white px-1.5 py-1 text-xs">Agent QA</p>
            </div>
          </div>
          <div>
            <p className="text-[0.84rem] font-normal">
              Performance score of <span className="font-semibold">{ticketData?.metadata?.metadata?.agent_name}</span>
            </p>
          </div>
        </div>
        <div className="mt-4 flex h-fit gap-2" role="button" onClick={() => setOpenQAAgent(!openQAAgent)}>
          <div className="text-sm font-normal text-gray-500">View details</div>
          <span className="flex items-center">
            <FiChevronRight className="size-4 text-gray-500" />
          </span>
        </div>
      </div>
      <AgentQaContainer open={openQAAgent} handleChange={setOpenQAAgent} ticketData={ticketData} analysisFields={analysis} />
    </>
  );
};
