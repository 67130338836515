import { useLDFlags } from 'Hooks/useLaunchDakly';
import React, { FC } from 'react';
import { Chat } from '../../../types';
import { AgentQaQuestionAnswer } from './AgentQaQuestionAnswer';
import { structuredQaData } from '../AgentQa';

export interface QAField {
  id: number;
  question: string;
  score: string;
  summary: string;
}

interface Category {
  name: string;
  qaFields: QAField[];
}

interface QaReportFieldsProps {
  ticketData?: any;
  analysisFields?: Chat[];
}

export const QaReportFields: FC<QaReportFieldsProps> = ({ ticketData, analysisFields }) => {
  const AnalysisQuestions = useLDFlags().commentTypes;
  const AnalysisQuestionsObj: Record<string, { label: string; qaValue: string }> = JSON.parse(AnalysisQuestions);
  const AnalysisQuestionMap = new Map(Object.entries(AnalysisQuestionsObj));

  const transformedData: Category[] = Object.keys(structuredQaData).map((sectionName) => ({
    name: sectionName,
    qaFields: Object.keys(structuredQaData[sectionName]).map((key, index) => ({
      id: index + 1,
      question: structuredQaData[sectionName][key].label,
      score: ticketData?.metadata?.metadata[AnalysisQuestionMap.get(key)?.qaValue],
      summary: analysisFields.find((item) => item.commentType === key)?.message,
    })),
  }));

  return (
    <div className="space-y-5">
      {transformedData.map((item, index) => {
        if (item.qaFields.every((field) => field.score === undefined) || item.qaFields.every((field) => field.summary === undefined)) return null;
        const validQaFields = item.qaFields.filter((item) => item.score && item.summary);
        return (
          <div key={index} className="rounded-xl border border-gray-200 p-3">
            <div className="mt-2 flex justify-between text-base font-semibold">{item.name}</div>
            <AgentQaQuestionAnswer qaFields={validQaFields} />
          </div>
        );
      })}
    </div>
  );
};
