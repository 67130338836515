import { useQuery } from '@tanstack/react-query';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { getSummaryOverviewGraphData } from 'src/api/Summary';

export const metricsMap = new Map([
  ['conversationResolution', 'percentage_conversations_resolved'],
  ['agentPerformanceSummaryProfessionalism', 'percentage_professional_agent'],
  ['agentPerformanceSummaryEmpathy', 'percentage_empathy_shown_by_agent'],
  ['agentPerformanceSummaryFriendliness', 'percentage_friendliness_shown_by_agent'],
  ['opening', 'percentage_opening_etiquette_shown_by_agent'],
  ['closing', 'PERCENT_CLOSING_ETIQUETTE_SHOWN_BY_AGENT'],
  ['verification', 'percentage_verification_done_by_agent'],
  ['clientFocusUnderstanding', 'percentage_client_focused_understanding_shown_by_agent'],
  ['clientFocusListening', 'percentage_client_focused_listening_shown_by_agent'],
  ['agentPerformanceSummaryKnowledge', 'percentage_knowledge_shown_by_agent'],
]);

export const AgentFullReportField = ({ index, commentType, metadata, AnalysisQuestionMap, stateData }) => {
  const metrics = stateData.selectedSourceClientData?.es_alias?.metrics;
  const metric = metrics.find(({ name }) => name === metricsMap.get(commentType));
  if (!metric) return null;

  const { data, isLoading } = useQuery({
    queryKey: ['individualAgentScore', stateData.filters.dateRange, metadata.agent_name, commentType],
    queryFn: () => getSummaryOverviewGraphData({ metric }, stateData),
  });

  if (isLoading) return <Skeleton height={50} className="my-1.5" />;

  return (
    <div className="flex justify-between p-3">
      <div className="flex items-center gap-4">
        <div className="flex h-5 w-5 items-center justify-center rounded-full bg-indigo-600 text-xs font-medium text-white">{index + 1}</div>
        <p className="text-sm font-semibold leading-tight">{AnalysisQuestionMap.get(commentType)?.label} </p>
      </div>
      <p className="rounded-lg bg-indigo-25 px-1.5 py-1 text-base font-semibold capitalize text-indigo-600">
        {data?.data[`${metricsMap.get(commentType)}`].current_interval?.metrics[0]?.value}%
      </p>
    </div>
  );
};
