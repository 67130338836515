import React, { FC } from 'react';
import { HiChevronLeft, HiOutlineSparkles, HiSparkles } from 'react-icons/hi2';
import { QuickSummaryResult } from './QuickSummaryResult';
import { useQuickSummaryDiginContext } from '../QuickSummaryDigInContext';
import { SummaryLoader } from './SummaryLoader';
import { FailedToGenerateQuickSummary } from './FailedToGenerateQuickSummary';
import { useQuickInsight } from '../QuickInsightContainer';
import { useAppSelector } from 'src/core/store';
import { cn } from 'Utils/TailwindUtils';
import { QuickInsightBreadCrumb } from '../Conversations/QuickInsightBreadCrumb';
import { useSearchParams } from 'react-router-dom';
import { Question } from 'Pages/Discover/Analytics/AnalyticsPane/TopicContent/OverTime/DigInQuestionSelect/DigInQuestions/Question';
import { questions } from 'Pages/Discover/Analytics/AnalyticsPane/TopicContent/OverTime/DigInQuestionSelect/constants';
import { FiSearch } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { Button } from 'src/components/Button';
import { useFetchQuickSummary } from '../useFilterState';
import { ISelectedClientSourceData } from 'src/types/User';
import { useLDFlags } from 'Hooks/useLaunchDakly';
import { useQuery } from '@tanstack/react-query';
import { getDepopQuestions } from 'src/api/WhiteLabel';

interface QuickSummaryProps {
  navigateToDiscover: () => void;
  timer: number;
  selectedClientSourceData: ISelectedClientSourceData;
}

export const QuickSummary: FC<QuickSummaryProps> = ({ navigateToDiscover, timer, selectedClientSourceData }) => {
  const { showAgentQaCard } = useLDFlags();
  const [{ summaryStatus, selectedQuestion }, setDigInState] = useQuickSummaryDiginContext();
  const { selectedDimension, state, topicPayload, dimension } = useQuickInsight();
  const { selectedDrillDownTopic } = useAppSelector((state) => state.filter);
  const [searchParams] = useSearchParams();
  const [customQuestion, setCustomQuestion] = React.useState('');
  const { fetchQuickSummary } = useFetchQuickSummary(setDigInState);
  const questionsQuery = useQuery({
    queryKey: ['questions', selectedClientSourceData, searchParams.get('dimensionValue')],
    queryFn: async () => {
      const aspectQuestions = await getDepopQuestions();
      return aspectQuestions.filter(({ topic }) => topic === searchParams.get('dimensionValue'))[0]?.questions ?? questions;
    },
  });

  const selectQuestion = (question: string) =>
    fetchQuickSummary(
      state,
      selectedDrillDownTopic,
      selectedClientSourceData,
      dimension,
      {
        aspect: searchParams.get('aspect'),
        ...topicPayload,
      },
      question
    );
  return (
    <>
      {(selectedDrillDownTopic || searchParams.get('aspect')) && <QuickInsightBreadCrumb selectedDimension={selectedDimension} isDigin />}
      <div
        className={cn('mr-5 flex h-[91%] gap-2.5 overflow-y-auto rounded-xl bg-indigo-25 px-4', {
          'h-[86%]': selectedDrillDownTopic || searchParams.get('aspect'),
        })}
      >
        {selectedQuestion ? (
          <div className="mt-7">
            <button
              className="flex items-center justify-center rounded-full bg-indigo-600 px-2.5 py-2.5"
              onClick={() => setDigInState({ summaryStatus: 'idle' })}
            >
              {showAgentQaCard ? <HiChevronLeft className="fill-white text-white" /> : <HiOutlineSparkles className="fill-white text-white" />}
            </button>
          </div>
        ) : (
          <div className="mt-8 flex w-full flex-col items-center gap-6">
            <div className="flex w-full items-center">
              <span className="flex items-center justify-center rounded-full bg-indigo-600 px-2.5 py-2.5">
                <HiOutlineSparkles className="fill-white text-white" />
              </span>
              <span className="ml-2 text-xl font-semibold">Deep Dive into conversations</span>
            </div>
            <div className="flex w-full items-center justify-between gap-3 rounded-2xl border border-grey-200 bg-white p-1.5 pl-4">
              <div className="flex grow gap-3">
                <FiSearch size={18} className="text-gray-500" />
                <motion.input
                  initial={customQuestion ? { width: '100%', flexGrow: 1 } : { width: 0, flexGrow: 0 }}
                  animate={{ width: '100%', flexGrow: 1 }}
                  transition={{ duration: 5 }}
                  type="text"
                  placeholder=" Start typing your question here..."
                  className="text-sm text-grey-800 placeholder-grey-400"
                  value={customQuestion}
                  onChange={(e) => setCustomQuestion(e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && selectQuestion(customQuestion)}
                />
              </div>
              <Button
                variant="dark"
                icon={<HiSparkles />}
                label="Dig In"
                className="shrink-0 gap-2 rounded-full text-xs"
                disabled={!customQuestion}
                onClick={() => selectQuestion(customQuestion)}
              />
            </div>
            <div className="flex w-full flex-col gap-4 rounded-xl border border-grey-200 bg-white p-4">
              <span className="text-xxs/4 uppercase text-grey-500">Suggested questions you may ask</span>
              <div className="flex flex-col-reverse justify-between gap-2 xl:flex-row">
                <div className="grow space-y-5">
                  <Question selectQuestion={selectQuestion} question="Overall Summary" />
                  {questionsQuery?.data?.map((question, i) => (
                    <Question key={i} selectQuestion={selectQuestion} question={question} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        {summaryStatus === 'pending' && <SummaryLoader timer={timer} />}
        {summaryStatus === 'fulfilled' && <QuickSummaryResult navigateToDiscover={navigateToDiscover} />}
        {summaryStatus === 'rejected' && <FailedToGenerateQuickSummary />}
      </div>
    </>
  );
};
