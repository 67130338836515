import React from 'react';
import { Avatar } from 'src/components/Avatar';
import { cn } from 'Utils/TailwindUtils';

export const AgentProfileCard = ({ agentName, className }: { agentName: string; className?: string }) => {
  return (
    <div className={cn('mt-2 flex items-center gap-2 rounded-xl bg-indigo-25 py-2 pl-2.5 pr-10', className)}>
      <Avatar name={agentName} type="div" />
      <p className="text-lg font-semibold">{agentName}</p>
    </div>
  );
};
