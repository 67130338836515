export const structuredQaData = {
  'Conversation Outcome & Resolution': {
    callOutcome: {
      label: 'Call outcome:',
      qaValue: '',
    },
    conversationOutcome: {
      label: 'Conversation Outcome:',
      qaValue: '',
    },
    conversationResolution: {
      label: 'Was the conversation resolved?',
      qaValue: 'Conversation Resolved',
    },
    callSummarySentimentAnalysis: {
      label: 'Sentiment Journey:',
      qaValue: '',
    },
  },
  'Language & Communication': {
    conversationFluencySummaryBritishEnglish: {
      label: 'Did the customer use British English in their communication?',
      qaValue: 'British English',
    },
    conversationFluencySummaryGrammarAndSpelling: {
      label: 'Did the agent maintain proper grammar and spelling?',
      qaValue: 'Grammar And Spelling',
    },
    conversationFluencySummaryConversationFlow: {
      label: 'Did the conversation have a smooth flow?',
      qaValue: 'Conversation Fluency Score',
    },
    conversationFluencySummaryClarityOfResponse: {
      label: 'Was the response from the agent clear?',
      qaValue: 'Conversation Flow',
    },
  },
  'Product & Process Knowledge / Advisory': {
    knowledgeSummaryKnowledgeDepth: {
      label: 'Did the agent demonstrate enough knowledge about the products and processes of the company?',
      qaValue: 'Knowledge Depth',
    },
    knowledgeSummaryProblemUnderstanding: {
      label: 'Did the agent understand the underlying query/issue?',
      qaValue: 'Problem Understanding',
    },
    knowledgeSummaryBestAdvice: {
      label: 'Did the agent provide the best possible advice and additional information by showing in-depth knowledge of other services offered?',
      qaValue: 'Additional Advice',
    },
    knowledgeSummaryConversationClosing: {
      label: 'Did the agent ask if the customer had any additional questions or issues before ending the conversation?',
      qaValue: 'Conversation Closing',
    },
  },
  'Agent Behavior & Tone': {
    agentPerformanceSummaryEmpathy: {
      label: 'Did the agent show empathy?',
      qaValue: 'Agent Empathy',
    },
    agentPerformanceSummaryFriendliness: {
      label: 'Did the agent maintain a friendly tone?',
      qaValue: 'Agent Friendliness',
    },
    agentPerformanceSummaryProfessionalism: {
      label: 'Did the agent maintain a professional tone?',
      qaValue: 'Agent Professionalism',
    },
  },
  'Customer Engagement Journey': {
    opening: {
      label: 'Did the agent welcome the customer and introduce themselves?',
      qaValue: 'Opening',
    },
    verification: {
      label: 'Did the agent verify the customer by asking specific questions?',
      qaValue: 'Verification',
    },
    clientFocusUnderstanding: {
      label: "Did the agent listen to the client and understand what the client's query was about?",
      qaValue: 'ClientFocus Understanding',
    },
    clientFocusListening: {
      label: 'Did the agent listen without interrupting?',
      qaValue: 'ClientFocus Listening',
    },
    agentPerformanceSummaryKnowledge: {
      label: 'Did the agent demonstrate enough knowledge about the processes of the company?',
      qaValue: 'Agent Knowledge',
    },
    closing: {
      label: 'Did the agent ask the customer if they had any additional questions or issues before ending the conversation?',
      qaValue: 'Closing',
    },
  },
  'Critical Failures & Compliance': {
    autofail: {
      label: 'Did the agent exhibit any behavior or action that would result in an automatic failure of the assessment?',
      qaValue: 'Autofail',
    },
  },
  'Bot Performance': {
    botFeedback: {
      label: 'Bot Performance:',
      qaValue: '',
    },
  },
};
